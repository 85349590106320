import { IAppTheme } from '@msx/platform-services';

export const getStyles = (theme: IAppTheme) => {
    return {
        callOutStyle: {
            selectors: {
                '.ms-ContextualMenu-icon': {
                    fontSize: '14px'
                },
                '.ms-ContextualMenu-container': {
                    padding:'15px',
                }
            }   
        },
        iconProp: {
            selectors: {
                ':hover':{
                    '.ms-Button-menuIcon':{
                        background: "transparent",
                    }
                },
                '.ms-Button-menuIcon':{
                    color: "#FFFFFF !important"
                }
            }
        }
    }
};


