import React, { useEffect } from "react";
import { injectIntl } from "react-intl";
import {
  DocumentCard,
  DocumentCardImage,
  ImageFit,
  DocumentCardTitle,
  Link,
  classNamesFunction,
} from "@fluentui/react";
import { InjectedIntlProps } from "react-intl";
import { withRouter } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import { messages } from "./Help.messages";
import myConsumption from "../../images/myConsumption.png";
import addScenario from "../../images/addScenario.png";
import oppConsumptionEstimate from "../../images/oppConsumptionEstimate.png";
import environments from "../../images/environments.png";
import quickStart from "../../images/quickStart.png";
import importAPC from "../../images/importAPC.png";
import creatingROMEstimates from "../../images/creatingROMEstimates.png";
import { useSelector } from "react-redux";
import { getCurrentTheme } from "../../../core/store";
import { getStyles } from "./Help.styles";

interface OwnProps extends InjectedIntlProps {
  isAppReady: boolean;
}

type Props = OwnProps & RouteComponentProps;

const getClassNames = classNamesFunction<any, any>();
let classes: any;
const HelpComponent: React.FC<Props> = (props) => {
  const { intl } = props;


  const theme = useSelector(getCurrentTheme);
  classes = getClassNames(getStyles, theme);
  
  const renderMain = (): JSX.Element => {
    const videoLinks = [
      {key: "quickStart", href: "https://aka.ms/oseCEvNext/opportunityGettingStarted", img: quickStart, text: intl.formatMessage(messages.quickStartGuide)},
      {key: "creatingROMEstimates", href: "https://aka.ms/oseCEvNext/ROMGettingStarted", img: creatingROMEstimates, text: intl.formatMessage(messages.creatingRomEstimate)},
      {key: "myConsumption", href: "https://aka.ms/oseCEvNext/MyConsumptionEstimates", img: myConsumption, text: intl.formatMessage(messages.myconsumptionsEstimate)},
      {key: "oppConsumptionEstimate", href: "https://aka.ms/oseCEvNext/OpportunityConsumptionEstimates", img: oppConsumptionEstimate, text: intl.formatMessage(messages.oppconsumptionsEstimate)},
      {key: "addScenario", href: "https://aka.ms/oseCEvNext/AddScenario", img: addScenario, text: intl.formatMessage(messages.addScenario)},
      {key: "environments", href: "https://aka.ms/oseCEvNext/Environments", img: environments, text: intl.formatMessage(messages.envoronments)},
      {key: "importApc", href: "https://aka.ms/apcimporthelpvideo", img: importAPC, text: intl.formatMessage(messages.importApcHelpText)},
    ]
    return (
      <div>
        <p className={classes.paraStyles}>
          {intl.formatMessage(messages.helpPara)}
        </p>
        <p className={classes.paraStyles}>
          {intl.formatMessage(messages.helpParaLinkInfo)}{" "}
          <span>
            <Link
              className={classes.linkStyles}
              target="_blank"
              href="https://aka.ms/OSECE2.0Guide"
            >
              {intl.formatMessage(messages.linkHereText)}
            </Link>
          </span>
        </p>
        <div className={classes.cardGroupStyle}>
          {videoLinks.map((item) => (
            <DocumentCard
              onClickHref={item.href}
              onClickTarget="_blank"
              className={classes.cardStyles}
              key={item.key}
            >
              <DocumentCardImage
                className={classes.imageStyles}
                imageFit={ImageFit.cover}
                imageSrc={item.img}
              />
              <DocumentCardTitle
                title={item.text}
                className={classes.titleStyle}
              />
            </DocumentCard>
          ))}
        </div>
      </div>
    );
  };

  return renderMain();
};
export const Help = withRouter(injectIntl(HelpComponent));