import { Checkbox, DefaultButton, Dialog, DialogFooter, DialogType, IDialogStyleProps, IDialogStyles, IStyleFunctionOrObject, Link, PrimaryButton, classNamesFunction } from "@fluentui/react"
import { RouteComponentProps, withRouter } from "react-router";
import { InjectedIntlProps, injectIntl } from "react-intl";
import { messages } from "./Announcements.messages";
import { getStyles } from "./Announcements.styles";
import { useSelector } from "react-redux";
import { getCurrentTheme } from "../../../core/store";
import NoDataImage from "../../images/noDataImage.svg";
import lightThemeBackground from "../../images/modal_Background_Light.png";
import darkThemeBackground from "../../images/modal_Background_dark.png";
import { useEffect, useState } from "react";
import { appConfig } from "../../App.config";

interface OwnProps extends InjectedIntlProps {
    filteredAnnouncements: any;
    hideDialog: boolean;
    toggleHideDialog: (dontShowAgain: boolean) => void; 
    openedThroughMenu: boolean;
    title: string,
    subText: string
}
type Props = OwnProps & RouteComponentProps


const getClassNames = classNamesFunction<any, any>();
let classes: any;

const WhatsNewComponent : React.FC<Props> = ({intl, filteredAnnouncements, hideDialog, toggleHideDialog, openedThroughMenu, title, subText}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const theme = useSelector(getCurrentTheme);
    classes = getClassNames(getStyles, theme);

    const dialogContentProps = {
        type: DialogType.normal,
        title:'\u00A0',
        closeButtonAriaLabel: 'Close',
        className: classes.dialogContent
      };


    const modalProps = {
        isBlocking: true,
        className: classes.modalContainer
    }
    const [imageError, setImageError] = useState(false);
    const [imageUrl, setImageUrl] = useState('');
    const [backgroundImageUrl, setBackgroundImageUrl] = useState('');

    useEffect(() => {
      const tempUrl = appConfig.isLocalAnnouncement
      ? `/data/announcement/${filteredAnnouncements[currentIndex]?.imageName}`
      : `${process.env.REACT_APP_ANNOUNCEMENT_CDN_IMAGE_FOLDER_URL}${filteredAnnouncements[currentIndex]?.imageName}?${process.env.REACT_APP_CDN_TOKEN}`;
      setImageUrl(tempUrl);

      const tempBackgroundImageUrl = theme.name === 'default' ? lightThemeBackground : darkThemeBackground;
      setBackgroundImageUrl(tempBackgroundImageUrl);
    },[currentIndex, theme, filteredAnnouncements]);

    const handleImageError = () => {
      setImageError(true);
    };

    const handlePrevClick = () => {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    };

    const handleNextClick = () => {
      if (currentIndex < filteredAnnouncements.length -1 ) {
        setCurrentIndex(currentIndex + 1);
      }
    };

    const [dontShowAgain, setDontShowAgain] = useState(false);
    const handleOnFinishClicked =  (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setDontShowAgain(true);
      toggleHideDialog(true);
    }

    function toggleHide(event: any): void {
      toggleHideDialog(dontShowAgain);
    }

    return (
      <Dialog
        minWidth={600}
        maxWidth={700}
        hidden={hideDialog}
        onDismiss={toggleHide}
        dialogContentProps={dialogContentProps}
        modalProps={modalProps}
      >
        {!!filteredAnnouncements.length ? (
          <div>
            <div className={classes.announcementContentContainer}>
              {!imageError && filteredAnnouncements[currentIndex].imageName && (
                <div className={classes.imageContainerDiv} style={{backgroundImage: `url(${backgroundImageUrl})`}}>
                  <img
                    src={`${imageUrl}?timestamp=${process.env.REACT_APP_BUILD_TIMESTAMP}`}
                    className={classes.imageContainer}
                    alt={`${title}`}
                    onError={handleImageError}
                  />
                </div>
              )}
              <div className={classes.bodyDescriptionStyles}>
                {filteredAnnouncements[currentIndex].announcementDescription.map(
                  (item, index) => (
                    <div className={classes.subHeadingContainer} key={index}>
                      <h5 className={classes.subheading}>{item.subheading}</h5>
                      <div className={classes.descriptionContainer}>
                        <p className={classes.description}>{item.description}</p>
                        {!!item.bulletPoints.length && (
                          <ul className={classes.bulletPoints}>
                            {item.bulletPoints.map((bullet, bulletIndex) => (
                              <li key={bulletIndex}>{bullet}</li>
                            ))}
                          </ul>
                        )}
                        {item.wikiLink && (
                          <Link href={item.wikiLink} target="_blank" className={classes.knowMoreLink}>
                            {intl.formatMessage(messages.knowMoreLink)}
                          </Link>
                        )}
                        {item.videoLink && (
                          <Link href={item.videoLink} target="_blank" className={classes.knowMoreLink}>
                            {intl.formatMessage(messages.videoLink)}
                          </Link>
                        )}
                        <br />
                        <br />
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={NoDataImage} alt={intl.formatMessage(messages.noNewAnnouncements)}/>
          </div>
        )}
        <DialogFooter
          className={classes.dialogFooter}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className={classes.stepper}>
              {filteredAnnouncements.length > 1 && filteredAnnouncements.map((announcement, index) => (
                <div
                  key={index}
                  className={`${classes.step} ${currentIndex === index ? classes.activeStep : ''}`}
                />
              ))}
            </div>
            <div className={classes.footerButtonContainer}>
              {currentIndex === 0 ? (
                <DefaultButton
                  text={intl.formatMessage(messages.notNowButton)}
                  onClick={toggleHide}
                  style={{ marginRight: '10px' }}
                />
              ) : (
                <DefaultButton
                  text={intl.formatMessage(messages.previous)}
                  onClick={handlePrevClick}
                  disabled={currentIndex === 0}
                  style={{ marginRight: '10px' }}
                />
              )}
              {currentIndex === filteredAnnouncements.length - 1 ? (
                <PrimaryButton
                  text={intl.formatMessage(messages.finishButton)}
                  onClick={handleOnFinishClicked}
                  style={{ marginRight: '10px' }}
                />
              ) : (
                <PrimaryButton
                  text={intl.formatMessage(messages.next)}
                  onClick={handleNextClick}
                  disabled={currentIndex === filteredAnnouncements.length - 1}
                  style={{ marginRight: '10px' }}
                />
              )}
            </div>
          </div>
        </DialogFooter>
      </Dialog>
    );
}

export const WhatsNew = withRouter(injectIntl(WhatsNewComponent));