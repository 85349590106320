import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  helpPageSearchPlaceHolder: {
    id: 'help.helpPageSearchPlaceHolder',
    defaultMessage: 'Search help'
  },
  helpPageTile: {
    id: 'help.Title',
    defaultMessage: 'Help'
  },
  helpPageSubTile: {
    id: 'help.helpPageSubTile',
    defaultMessage: 'Featured help'
  },
  helpPara: {
    id: 'help.helpPara',
    defaultMessage: 'Here are a few useful help videos you can refer to understand the functionality of each of the pages of Consumption Estimator 2.0'
  },
  helpParaLinkInfo: {
    id: 'help.helpPara',
    defaultMessage: 'For more information and FAQs, please refer to the'
  },
  myconsumptionsEstimate: {
    id: 'help.myconsumptionsEstimate',
    defaultMessage: 'My Consumption Estimates'
  },
  oppconsumptionsEstimate: {
    id: 'help.oppconsumptionsEstimate',
    defaultMessage: 'Opportunity Consumption Estimates'
  },
  addScenario: {
    id: 'help.addScenario',
    defaultMessage: 'Adding Scenarios'
  },
  envoronments: {
    id: 'help.envoronments',
    defaultMessage: 'Creating Environments'
  },
  quickStartGuide: {
    id: 'help.quickStartGuide',
    defaultMessage: 'Quick Start Guide'
  },
  creatingRomEstimate: {
    id: 'help.creatingRomEstimate',
    defaultMessage: 'Creating ROM Estimate'
  },
  linkHereText: {
    id: 'help.linkHereText',
    defaultMessage: 'Wiki'
  },
  importApcHelpText:{
    id: 'help.importApcHelpText',
    defaultMessage: 'Import your APC Estimate'

  }
});

