
import React, { useEffect, useState, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { CoherenceHeader, NotificationListItem, CoherencePanelSize, ItemDisplayStatus, ItemStatus } from '@coherence-design-system/controls';
import { MsxSearchBox, ServiceContext, ApplicationContext, SuggestionBoxProps } from '@msx/platform-services';
import { messages } from './AppHeader.messages';
import { HeaderProps } from './AppHeader.types';
import { Help } from '..';
import { Settings } from '..'
import { Stack, Panel, PanelType, DirectionalHint, classNamesFunction, IContextualMenuItemProps, IContextualMenuItem, IContextualMenuProps, Dialog, DialogFooter, PrimaryButton, DefaultButton, DialogType } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import { IAppConfig } from '../../App.config';
import { getNotifications, fetchNotificationsSuccess, } from '../../../app/store';
import { INotificationItem } from '../../interfaces';
import { Extension, ExtensionTypes } from '../../../core/components';
import { withRouter,useHistory } from 'react-router-dom';

import { AppRoutePath } from '../../App.types';
import dayjs from 'dayjs';
import MCAPSBotContainer from '@csttools/ose';
import {ReactComponent as CopilotIcon}  from '../../images/CopilotWhite.svg';
import { HttpClient } from '@msx/platform-services';
import { DataServiceContext } from '../../context';
import { getCurrentTheme, getDismissedAnnouncements, setDismissedAnnouncements } from '../../../core/store';
import { getStyles } from './AppHeader.styles';
import { WhatsNew } from '../Announcements/Announcements';
import { IAnnouncement, IAnnouncementDetail } from '../../interfaces/IAnnouncement';


const getClassNames = classNamesFunction<any, any>();
let classes: any;

const AppHeaderComponent: (React.FC<HeaderProps>) = (props) => {
  const reduxDispatch = useDispatch();
  const { extensionsRegistrationClient, appState } = useContext(ApplicationContext);
  const context = React.useContext(ServiceContext)
  const notifications = useSelector(getNotifications);
  const [notificationCount, setNotificationCount] = useState(notifications ? notifications.length : 0);
  const [notificationItems, setNotificationItems] = useState<NotificationListItem[]>([]);
  const [dismissOpenedPanel, setDismissOpenedPanel] = useState(undefined);
  const { intl, history, isUserLoggedIn, appName, isAppReady } = props;
  const [isNotificationsOpen, { setTrue: openNotificationsPanel, setFalse: dismissNotificationsPanel }] = useBoolean(false);
  const appConfig = appState.appConfig as IAppConfig;
  const [isMCAPSChatBotStarted, setIsMCAPSChatBotStarted] = useState(false);
  const [isHelpVideosOpen, setIsHelpVideosOpen] = useState(false);
  const [isHelpChecked, setIsHelpChecked] = useState(false)
  const theme = useSelector(getCurrentTheme);
  classes = getClassNames(getStyles, theme);
  const dismissedAnnouncements = useSelector(getDismissedAnnouncements)
  const [openedThroughMenu, setOpenedThroughMenu] = useState(false)
  const [filteredAnnouncements, setfilteredAnnouncements] = useState<IAnnouncementDetail[]>([])
  const [hideDialog, setHideDialog] = useState(true);
  const navigate = useHistory();
  

  useEffect(() => {
    processNotificaitons();
    // eslint-disable-next-line
  }, [notifications]);

  const filterAndSort = (announcements) => {
    return announcements.filter(
      announcement => {
      const startDate = new Date(announcement.startDate);
      const endDate = new Date(announcement.endDate);
      let currentDate = new Date()
      return endDate > currentDate && startDate <= currentDate;
      })
    .sort((a, b) => {
      const dateA = new Date(a.startDate).getTime();
      const dateB = new Date(b.startDate).getTime();
      return dateB - dateA;
    })
  }

  const [announcementJsondata, setannouncementJsondata] = useState<IAnnouncement>({} as IAnnouncement);
  const { dataService } = useContext(DataServiceContext);
  useEffect(()=>{
    const fetchData = async () => {
      const announcementJsondata:IAnnouncement = await dataService.GetAnnouncements();
      setannouncementJsondata(announcementJsondata);
      const filteredAnnouncements:IAnnouncementDetail[] = filterAndSort(
        announcementJsondata.Announcements
      ).filter(
        (announcement) =>
          !dismissedAnnouncements.some(
            (dismissed) => dismissed.id === announcement.id
          )
      );
      setfilteredAnnouncements(filteredAnnouncements);
      !!filteredAnnouncements.length && setHideDialog(false);
    };
  
    fetchData();
  }, [])

  const toggleHideDialog = (dontShowAgain : boolean) => {
    if(dontShowAgain) {
      announcementJsondata.Announcements.forEach((val) =>
        dismissedAnnouncements.push({ id: val.id, expiryDate: val.endDate })
      );
      reduxDispatch(
        setDismissedAnnouncements(
          dismissedAnnouncements.filter(
            (announcement) => new Date(announcement.expiryDate) > new Date()
          )
        )
      );
      localStorage.setItem(
        "dismissedAnnouncements",
        JSON.stringify(dismissedAnnouncements)
      );
    }
    setHideDialog(!hideDialog);
  };


  const convertINotificationItem2NotificationListItem = (item: INotificationItem) => {
    const nofication: NotificationListItem = {
      itemKey: item.itemKey,
      displayStatus: item.displayStatus as ItemDisplayStatus,
      status: item.status as ItemStatus,
      timeDelivered: item?.timeDelivered ? dayjs(item?.timeDelivered) : dayjs().add(-1, 'hours'),
      messageBodyText: item.messageBodyText,
      subjectIcon: item.subjectIcon,
      subjectHeader: item.subjectHeader,
      senderName: item.senderName,
      actionRequiredText: item.actionRequiredText,
      actionRequiredLink: item.actionRequiredLink
    }
    return nofication;
  }

  const processNotificaitons = () => {
    let count = 0;
    let tempNofications: NotificationListItem[] = [];
    if (notifications && notifications.length > 0) {
      notifications.forEach(item => {
        if (item.status === 'unread') count++;
        tempNofications.push(convertINotificationItem2NotificationListItem(item))
      });
    }
    setNotificationCount(count);
    setNotificationItems(tempNofications);

  }

  const handleOpenNotificationItem = (itemKey: string) => {
    console.log('Open notification ' + itemKey);
  }

  const handleUpdateNotificationItem = (itemKey: string, dStatus: NotificationListItem['displayStatus'], rStatus?: NotificationListItem['status']) => {
    let list = [...notifications];
    list.forEach((item) => {
      if (item.itemKey === itemKey) {
        item.displayStatus = dStatus;
        if (rStatus) {
          item.status = rStatus;
        }
      }
    });
    reduxDispatch(fetchNotificationsSuccess(notifications as INotificationItem[]))
  }

  const updateNotificationItemDisplayStatus = (): void => {
    // Placeholder for code that updates displayStatus to 'old' for all items listed in the id string array
    console.log('Mark notification(s) as old');

    // Setting to -1 to see badge disappear when notification panel opens
    setNotificationCount(-1);
  }


  const handleSignOut = () => {
    context.authClient.logOut();
  }

  const getHelpBody = () => {
    return <Help isAppReady={isAppReady} />
  }
  const handleDismissPanel = () => {
    setDismissOpenedPanel(true);
  }

  const getSettingsBody = () => {
    return <Settings onDismissPanel={handleDismissPanel} />
  }

  const pivotNotifications = () => {
    return (
      <div
        style={{
          padding: '20px',
          fontSize: '20px',
        }}
      >
        {' '}
        {props.intl.formatMessage(messages.noNewNotifications)}
      </div>
    );
  };

  const extName = extensionsRegistrationClient.getExtensions();

  const getNotificationSettings = (): any => {
    if (!appConfig.notificaitonConfig.active) return null;
    if (appConfig.notificaitonConfig.customPanel) return null;
    if (!notifications) return null;


    const item = {
      panelSettings:
      {
        titleText: intl.formatMessage(messages.settingsNotificationsTitle),
        headerBody: notificationItems.length > 0 ? null : pivotNotifications(),
        items: notificationItems,
        newNotifications: notificationCount,
        onPanelOpened: updateNotificationItemDisplayStatus,
        openItem: handleOpenNotificationItem,
        updateItem: handleUpdateNotificationItem,
        panelSize: CoherencePanelSize.medium,
      },
      buttonSettings: {
        title: intl.formatMessage(messages.notificationToolTip)
      }
    };
    return item;
  }

  const handleWhatsNewClicked = () => {
    //call end point for announcement data
    setOpenedThroughMenu(true)
    const filteredAnnouncements = filterAndSort(announcementJsondata.Announcements)
    setfilteredAnnouncements(filteredAnnouncements)
    setHideDialog(false)
  }

  const helpSubMenuItems: IContextualMenuItem[] = [
    {
      key: 'support',
      text: intl.formatMessage(messages.support),
      ariaLabel: intl.formatMessage(messages.support),
      iconProps: { iconName: 'headset' },
      href: process.env.REACT_APP_OSESUPPORT_URL,
      target: "_blank"
    },
    {
      key: 'helpVideos',
      text: intl.formatMessage(messages.helpVideos),
      ariaLabel: intl.formatMessage(messages.helpVideos),
      iconProps: { iconName: 'msnvideos' },
      onClick: () => {
        setIsHelpVideosOpen(!isHelpVideosOpen);
      }
    },
    {
      key: 'FAQ',
      text: intl.formatMessage(messages.FAQ),
      ariaLabel: intl.formatMessage(messages.FAQ),
      iconProps: { iconName: 'help' },
      href: process.env.REACT_APP_OSECE2FAQ_URL,
      target: "_blank"
    },
    {
      key: 'whatsNew',
      text: intl.formatMessage(messages.whatsNew),
      ariaLabel: intl.formatMessage(messages.whatsNew),
      iconProps: { iconName: 'megaphone' },
      onClick: handleWhatsNewClicked
    },
    {
      key: 'userVoice',
      text: intl.formatMessage(messages.userVoice),
      ariaLabel: intl.formatMessage(messages.userVoice),
      iconProps: { iconName: 'feedback' },
      href: process.env.REACT_APP_OSEUSERVOICE_URL,
      target: "_blank"
    },
    {
      key: 'privacyNotice',
      text: intl.formatMessage(messages.privacyNotice),
      ariaLabel: intl.formatMessage(messages.privacyNotice),
      iconProps: { iconName: 'shield' },
      href: process.env.REACT_APP_OSEPRIVACY_NOTICE_URL,
      target: '_blank'
    },
    {
      key: 'reachus',
      text: intl.formatMessage(messages.reachUs),
      ariaLabel: intl.formatMessage(messages.reachUs),
      iconProps: { iconName: 'Mail' },
      href: 'mailTo:osegovernance@microsoft.com', 
    },
    {
      key: 'aboutus',
      text: intl.formatMessage(messages.aboutUs),
      ariaLabel: intl.formatMessage(messages.aboutUs),
      iconProps: { iconName: 'Lightbulb' },
      href: '/aboutus', 
    }
  ];

  const helpSubMenuProps : IContextualMenuProps = {
    items: helpSubMenuItems,
    directionalHint: DirectionalHint.bottomRightEdge,
    shouldFocusOnMount: true,
    isSubMenu: true,
    calloutProps: {className: classes.callOutStyle},
    onMenuOpened: () => {setIsHelpChecked(true)},
    onMenuDismissed: () => {setIsHelpChecked(false)}
}

  const getFarItems = (): ICommandBarItemProps[] => {
    let items = [
      {
        key: 'chatBot',
        id: 'header-chat-bot',
        text: intl.formatMessage(messages.chatBot),
        ariaLabel: intl.formatMessage(messages.financeSupportBotAriaLabel),
        onRenderIcon: () => <CopilotIcon className="copilot-icon" />,
        iconOnly: true,
        checked: isMCAPSChatBotStarted,
        onClick: () => {
          setIsMCAPSChatBotStarted(!isMCAPSChatBotStarted);
        },
      },
      {
        key: 'gudedTour',
        id: 'header-guided-tour',
        text: intl.formatMessage(messages.retakeTour),
        ariaLabel: intl.formatMessage(messages.retakeTourAriaLabel),
        iconOnly: true,
        iconProps: {
          iconName: 'ReadingMode',
        },
        onClick: () => {
          console.log('Guided tour button clicked')
        }
      },
      {
        key: 'customNotificaiton',
        id: 'header-my-notifications',
        text: intl.formatMessage(messages.settingsNotificationsTitle),
        ariaLabel: intl.formatMessage(messages.settingsNotificationsTitle),
        iconOnly: true,
        iconProps: {
          iconName: 'Ringer',
        },
        onClick: handleNotificationsClick,
      },
      {
        key: 'help',
        id: 'header-help',
        text: intl.formatMessage(messages.customHelp),
        ariaLabel: intl.formatMessage(messages.customHelp),
        iconOnly: true,
        className: classes.iconProp,
        menuIconProps: {iconName: "help"},
        subMenuProps: helpSubMenuProps,
        checked: isHelpChecked
      }
    ];
    if (!isAppReady || !isUserLoggedIn) {
      items = items.filter(function (obj) {
        return obj.key !== 'chatBot' && obj.key !== 'gudedTour';
      });
      items = items.filter(function (obj) {
        return obj.key !== 'customNotificaiton';
      });
      return items;
    }
    if (!appConfig.notificaitonConfig.active || !appConfig.notificaitonConfig.customPanel) {
      items = items.filter(function (obj) {
        return obj.key !== 'customNotificaiton';
      });
    }
    if (!appConfig.botConfig.active) {
      items = items.filter(function (obj) {
        return obj.key !== 'chatBot';
      });
    }
    if (!appConfig.guidedTourConfig.active) {
      items = items.filter(function (obj) {
        return obj.key !== 'gudedTour';
      });
    }
    return items;
  }

  const handleNotificationsClick = () => {
    console.log('My notifications clicked');
    openNotificationsPanel();
  }

  const getMode = (): string => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("mode");
  };

  const renderNotificationsPanel = () => {
    if (!appConfig.notificaitonConfig.active) return null;
    if (!appConfig.notificaitonConfig.customPanel) return null;

    const panelStyle = { root: { marginTop: '48px' } };
    return (
      <Panel
        isLightDismiss
        isOpen={isNotificationsOpen}
        type={PanelType.medium}
        onDismiss={dismissNotificationsPanel}
        closeButtonAriaLabel="Close"
        headerText={intl.formatMessage(messages.settingsNotificationsTitle)}
        styles={panelStyle}
      >
        {renderNotificationsPanelBody()}
      </Panel>
    )
  }

  const renderNotificationsPanelBody = (): JSX.Element => {
    return (
      <Stack horizontalAlign={'start'}>
        <Stack.Item>
          {'display notification items here'}
        </Stack.Item>
      </Stack>
    );
  }

  const closeCopilot = () => {
    setIsMCAPSChatBotStarted(false);
  }

  const renderMCAPSChatBot = (): JSX.Element => {
    return (
      <MCAPSBotContainer
        appName={extName[0].name + ' Copilot (Beta)'}
        currentUserName={props.user.name}
        isMCAPSChatBotStarted={false}
        isDBCopilotBotStarted={true}
        isCopilotStartedForProject={false}
        apiBaseUrl={process.env.REACT_APP_CHATBOT_BASE_URL}
        panelwidth="480px"
        marginTop={49}
        msalInstance={context.authClient.authContext}
        redirectUri={[window.location.origin]}
        scopes={[process.env.REACT_APP_API_RESOURCE]}
        appInsightConfig={{ instrumentationKey: process.env.REACT_APP_INSTRUMENTATION_KEY }}
        mcapsBotSummaryWelcomeMessage={intl.formatMessage(messages.copilotWelcomeMessage)}
        mcapsBotSummaryWelcomeSuggestions={intl.formatMessage(messages.copilotWelcomeSuggestion)}
        closeFlyout={() => { closeCopilot() }}
        isBotOpened={isMCAPSChatBotStarted}
        theme={appState.theme?.palette}
        themeName={appState.theme?.name}
        createSupportTicketUrl=""
      />
    );
  }

  const renderFullHeader = (): JSX.Element => {
    return <CoherenceHeader
      styles={{
        subComponentStyles: {
          appNameLink: {
            root: { color: "#FFFFFF !important", 
            selectors: {
              ':hover':{
                  color: "#FFFFFF !important"
              }
          }},
          }
        },
      }}
      headerLabel={'header'}
      appNameSettings={{
        label: appName
      }}
      searchSettings= {{
        placeholder: intl.formatMessage(messages.globalSearchPlaceHolderText),
        onSearch: (newValue: string) => {
          history.push('/searchnew?searchText=' + newValue);
        }
      }}
      farRightSettings={{
        additionalItems: getFarItems(),
        // notificationsSettings: getNotificationSettings(),
        settingsSettings: {
          panelSettings: {
            titleText: intl.formatMessage(messages.settingsSettingsTitle),
            body: getSettingsBody(),
            panelSize: CoherencePanelSize.medium,
          }
        },
        
        // feedbackSettings: {
        //   panelSettings: {
        //     ocvButtonIsFocused: false,
        //     onClick: () => {
        //       const Window = window as any;
        //       //Window.startMultiFeedback_AllOptional();
        //       return false;
        //     },
        //   }
        // },
        profileSettings: {
          panelSettings: {
            fullName: props.user.name,
            emailAddress: props.user.email,
            imageUrl: '',
            logOutLink: '#',
            onLogOut: () => handleSignOut(),
            onRenderFooter: () => { return <a href='https://privacy.microsoft.com/en-us/privacystatement' rel="noopener noreferrer" target="_blank">Privacy Notice</a> },
          }
        }
      }}
      dismissOpenedPanel={dismissOpenedPanel}
      onDismissOpenedPanel={() => {
        setDismissOpenedPanel(undefined);
      }}
    />
  }

  const renderFullHeaderForSearchPage = (): JSX.Element => {
    return <CoherenceHeader
      styles={{
        subComponentStyles: {
          appNameLink: {
            root: { color: "#FFFFFF !important", 
            selectors: {
              ':hover':{
                  color: "#FFFFFF !important"
              }
          }},
          }
        },
      }}
      headerLabel={'header'}
      appNameSettings={{
        label: appName
      }}
      farRightSettings={{
        additionalItems: getFarItems(),
        // notificationsSettings: getNotificationSettings(),
        settingsSettings: {
          panelSettings: {
            titleText: intl.formatMessage(messages.settingsSettingsTitle),
            body: getSettingsBody(),
            panelSize: CoherencePanelSize.medium,
          }
        },
        
        
        // feedbackSettings: {
        //   panelSettings: {
        //     ocvButtonIsFocused: false,
        //     onClick: () => {
        //       const Window = window as any;
        //       //Window.startMultiFeedback_AllOptional();
        //       return false;
        //     },
        //   }
        // },
        profileSettings: {
          panelSettings: {
            fullName: props.user.name,
            emailAddress: props.user.email,
            imageUrl: '',
            logOutLink: '#',
            onLogOut: () => handleSignOut(),
            onRenderFooter: () => { return <a href='https://privacy.microsoft.com/en-us/privacystatement' rel="noopener noreferrer" target="_blank">Privacy Notice</a> },
          }
        }
      }}
      dismissOpenedPanel={dismissOpenedPanel}
      onDismissOpenedPanel={() => {
        setDismissOpenedPanel(undefined);
      }}
    />
  }

  const renderInitialHeader = (): JSX.Element => {
    return <CoherenceHeader
    styles={{
      subComponentStyles: {
        appNameLink: {
          root: { color: "#FFFFFF !important", 
          selectors: {
            ':hover':{
                color: "#FFFFFF !important"
            }
        }},
        }
      },
    }}
      headerLabel={'header'}
      appNameSettings={{
        label: appName
      }}
      farRightSettings={{
        additionalItems: getFarItems(),
      }}
    />
  }

  const renderNotLoggedInHeader = (): JSX.Element => {
    return <CoherenceHeader
    styles={{
      subComponentStyles: {
        appNameLink: {
          root: { color: "#FFFFFF !important", 
          selectors: {
            ':hover':{
                color: "#FFFFFF !important"
            }
        }},
        }
      },
    }}
      headerLabel={'header'}
      appNameSettings={{
        label: appName
      }}
      farRightSettings={{
        additionalItems: getFarItems(),
        settingsSettings: {
          panelSettings: {
            titleText: intl.formatMessage(messages.settingsSettingsTitle),
            body: getSettingsBody(),
          }
        },
      }}
    />
  }

  const renderMain = (): JSX.Element => {
    if (!isAppReady)
      return renderInitialHeader();
    if (!isUserLoggedIn)
      return renderNotLoggedInHeader();

    return (
      <>
        {window.location.pathname == '/searchnew'? renderFullHeaderForSearchPage():renderFullHeader()}
        {renderNotificationsPanel()}
        {isMCAPSChatBotStarted === true ? renderMCAPSChatBot() : null}
        <Panel
          onDismiss={() => setIsHelpVideosOpen(false)}
          isOpen={isHelpVideosOpen}
          isLightDismiss={true}
          headerText={intl.formatMessage(messages.customPanelHeaderText)}
          type={PanelType.custom}
          customWidth='644px'
          closeButtonAriaLabel={intl.formatMessage(messages.helpPanelDismissButtonAriaLabel)}
        >
          <p>{getHelpBody()}</p>
        </Panel>
        {!(getMode()?.toLocaleLowerCase() === "createestimate" || getMode()?.toLocaleLowerCase() === "importapc") && (
          <WhatsNew
            filteredAnnouncements={filteredAnnouncements}
            hideDialog={hideDialog}
            toggleHideDialog={toggleHideDialog}
            openedThroughMenu={openedThroughMenu}
            title={!!filteredAnnouncements.length ? announcementJsondata.title : ""}
            subText={!!filteredAnnouncements.length ? announcementJsondata.subTitle: ""}
          />
        )}

      </>
    );
  }

  return renderMain();

}

export const AppHeader = withRouter(injectIntl(AppHeaderComponent));
