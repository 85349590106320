import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  retakeTour: {
    id: 'header.retakeTour',
    defaultMessage: 'Guided tour',
  },
  retakeTourAriaLabel: {
    id: 'header.retakeTourAriaLabel',
    defaultMessage: 'retake guided tour',
  },
  localization: {
    id: 'header.localization',
    defaultMessage: 'Language menu',
  },
  notificationToolTip: {
    id: 'header.notificationToolTip',
    defaultMessage: 'Notifications',
  },
  localizationAriaLabel: {
    id: 'header.localizationAriaLabel',
    defaultMessage: 'change Language menu',
  },
  chatBot: {
    id: 'header.chatBot',
    defaultMessage: 'Copilot',
  },
  financeSupportBotAriaLabel: {
    id: 'header.financeSupportBotAriaLabel',
    defaultMessage: 'Copilot',
  },
  feedback: {
    id: 'header.feedback',
    defaultMessage: 'Feedback',
  },
  profile: {
    id: 'header.profile',
    defaultMessage: 'Profile menu',
  },
  expandMenu: {
    id: 'header.expandMenu',
    defaultMessage: 'expanded',
  },
  commandBarAriaLabel: {
    id: 'header.commandBarAriaLabel',
    defaultMessage: 'Main header, please use the left and right arrow keys to navigate between items',
  },
  globalSearch: {
    id: 'header.globalSearch',
    defaultMessage: 'Global Search',
  },
  globalSearchPlaceHolderText: {
    id: 'header.globalSearchPlaceHolderText',
    defaultMessage: 'Search Consumption Estimates',
  },
  helpSettingsTitle: {
    id: 'header.helpSettingsTitle',
    defaultMessage: 'Help',
  },
  helpSettingsBackButton: {
    id: 'header.helpSettingsBackButton',
    defaultMessage: 'Help Panel Back Button',
  },
  helpSettingsBackButtonAriaLabel: {
    id: 'header.helpSettingsBackButtonAriaLabel',
    defaultMessage: 'Help Panel Back Button for multiple level content navigation',
  },
  helpPanelDismissButtonAriaLabel: {
    id: 'header.customPanelDismissButtonAriaLabel',
    defaultMessage: 'Close',
  },
  settingsNotificationsTitle: {
    id: 'header.settingsNotificationsTitle',
    defaultMessage: 'Notifications',
  },
  settingsSettingsTitle: {
    id: 'header.settingsSettingsTitle',
    defaultMessage: 'Settings',
  },
  settingsSettingsPrimaryButton: {
    id: 'header.settingsSettingsPrimaryButton',
    defaultMessage: 'Save',
  },
  settingsSettingsPrimaryButtonAriaLabel: {
    id: 'header.settingsSettingsPrimaryButtonAriaLabel',
    defaultMessage: 'Save Settings',
  },
  settingsSettingsSecondaryButton: {
    id: 'header.settingsSettingsSecondaryButton',
    defaultMessage: 'Cancel',
  },
  settingsSettingsSecondaryButtonAriaLabel: {
    id: 'header.settingsSettingsSecondaryButtonAriaLabel',
    defaultMessage: 'Cancel current Settings',
  },
  noNewNotifications: {
    id: 'header.noNewNotifications',
    defaultMessage: 'You are all caught up! No new alerts or notifications.',
  },
  copilotName: {
    id: 'header.copilotName',
    defaultMessage: 'OSE Copilot',
  },
  copilotWelcomeMessage: {
    id: 'header.copilotWelcomeMessage',
    defaultMessage: "Hello! I am your Consumption Copilot here to help answer your questions. Please find below the top questions to get started with."
  },
  copilotWelcomeSuggestion: {
    id: 'header.copilotWelcomeSuggestion',
    defaultMessage: '["Is Consumption Estimator 2.0 is complete replacement of excel based estimator ?", "What are the new capabilities of Consumption Estimator 2.0 ?"]'
  },
  support: {
    id: 'header.support',
    defaultMessage: 'Raise a Support Ticket',
  },
  helpVideos: {
    id: 'header.helpVideos',
    defaultMessage: 'Help Videos',
  },
  FAQ: {
    id: 'header.FAQ',
    defaultMessage: 'FAQs',
  },
  whatsNew: {
    id: 'header.whatsNew',
    defaultMessage: "What's New",
  },
  userVoice: {
    id: 'header.userVoice',
    defaultMessage: 'User Voice',
  },
  privacyNotice: {
    id: 'header.privacyNotice',
    defaultMessage: 'Privacy Notice',
  },
  customHelp: {
    id: 'header.customHelp',
    defaultMessage: 'Help',
  },
  customPanelHeaderText: {
    id: 'header.customPanelHeaderText',
    defaultMessage: 'Help',
  },
  aboutUs: {
    id: 'header.aboutUs',
    defaultMessage: 'About Consumption Estimator',
  },
  reachUs: {
    id: 'header.reachUs',
    defaultMessage: 'Reach Us',
  },
});
