import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  loadingUserProfile: {
    id: 'app.loadingUserProfile',
    defaultMessage: 'Loading user profile...',
  },
  loadingExtensionRegistraton: {
    id: 'app.loadingExtensionRegistraton',
    defaultMessage: 'Loading registration config...',
  },
  headerLogoImageText: {
    id: 'app.imageAltText',
    defaultMessage: 'One Services Estimator Logo',
  }
});
